<template>
  <div class="solutions-wrap">
    <public-banner :banner-info="bannerInfo" />
    <public-bread-crumb :bread-crumb="navigator" />
    <public-title :data-info="solutionsData" site="left" />
    <div class="solutions-detail container">
      <div class="detail-des">
        {{ solutionsData.desc }}
      </div>
      <ul class="detail-list">
        <li v-for="(item, index) in detailList" :key="index" class="detail-item">
          <router-link :to="`/${$route.params.site}/${$route.params.lang}/solutions/detail?id=${item.id}`" class="detail-link">
            <div class="detail-img">
              <div class="img-bg" :style="`background: url(${item.image}) center center / cover no-repeat`"></div>
            </div>
            <div class="detail-content">
              <div class="title">{{ item.title }}</div>
              <div class="des">{{ item.title_description }}</div>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import PublicBanner from '@/components/public/PublicBanner.vue'
import PublicBreadCrumb from '@/components/public/PublicBreadCrumb.vue'
import PublicTitle from '@/components/public/PublicTitle.vue'
import { mapState } from 'vuex'
import { getSolutions } from '@/api/solutions'

export default {
  name: 'Solutions',
  components: {
    PublicBanner,
    PublicBreadCrumb,
    PublicTitle
  },
  data() {
    return {
      bannerInfo: {},
      solutionsData: {
        title: '',
        desc: ''
      },
      detailList: []
    }
  },
  computed: {
    ...mapState({
      banner: state => state.common.banner,
      navigator: state => state.common.navigator,
      whiteHeader: (state) => state.header.whiteHeader
    })
  },
  async created() {
    this.$store.commit('header/SET_WHITE_HEADER', false)
    if (this.$route.fullPath.includes('consumer')) {
      this.$router.push({
        path: `/${this.$route.params.site}/${this.$route.params.lang}/404`
      })
    }
    if (this.$route.fullPath.includes('business')) {
      await this.$store.dispatch('common/getBanner', { banner_id: 189 })
      this.bannerInfo = this.banner[0]
      await getSolutions().then(res => {
        ({ nav_name: this.solutionsData.title, nav_description: this.solutionsData.desc } = res.data.nav_solution)
        this.detailList = res.data.solution
        this.$store.commit('common/SET_MATE', res.data.seo)
      })
      await this.$store.dispatch('common/getNavigator', { type: 'solution_list', id: 1, level: 1 })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.solutions-wrap {
  /deep/ .public-bread-crumb {
    padding: 16px 0 24px;
    .header-item {
      margin-left: 11px;
    }
  }
  /deep/ .public-title {
    border-bottom: 1px solid #ddd;
    .text-box {
      padding: 8px 0 16px 0;
      .title {
        font-size: 24px;
      }
      .desc {
        display: none;
      }
    }
  }
  .solutions-detail {
    padding: 71px 0 72px;
    .detail-des {
      margin-bottom: 48px;
      font-size: 14px;
      color: #666666;
      line-height: 19px;
    }
    .detail-list {
      display: flex;
      height: 100%;
      .detail-item {
        flex: 1;
        margin-right: 17px;
        background-color: #F4F4F5;
        border-radius: 12px;
        &:last-child {
          margin-right: 0;
        }
        .detail-link {
          width: 100%;
          height: 100%;
          .detail-img {
            overflow: hidden;
            border-radius: 12px 12px 0 0;
            .img-bg {
              width: 100%;
              height: 400px;
              .transition;
            }
            &:hover {
              .img-bg {
                .scale();
              }
            }
          }
          .detail-content {
            padding: 16px 24px;
            .title {
              margin-bottom: 5px;
              font-size: 18px;
              font-weight: 600;
              color: #232931;
              line-height: 24px;
              .transition;
            }
            .des {
              font-size: 14px;
              color: #606266;
              line-height: 19px;
            }
          }
          &:hover {
            .detail-img {
              img {
                .scale;
              }
            }
            .detail-content {
              .title {
                color: @theme;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .solutions-detail {
      .detail-list {
        .detail-item {
          .detail-link {
            .detail-img {
              .img-bg {
                height: 350px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    /deep/ .public-title {
      .text-box {
        padding: 10px 0;
      }
    }
    .solutions-detail {
      padding: 24px 0 40px;
      .detail-list {
        flex-wrap: wrap;
        .detail-item {
          flex: auto;
          .detail-link {
            .detail-img {
              .img-bg {
                height: 240px;
              }
            }
          }
          & + .detail-item {
            margin-top: 16px;
          }
        }
      }
    }
  }
}
</style>
