<template>
  <div class="public-banner">
    <div
      class="banner-bg"
      :style="`background: url(${bannerInfoConfig.image}) center center / cover no-repeat; height: ${bannerInfoConfig.height}px`"
    >
      <div class="banner-info container">
        <!-- 带标题 居左 -->
        <div class="title-left-box">
          <h1 v-if="bannerInfo.title" class="left-title" :style="`color: ${bannerInfo.color}`">{{ bannerInfo.title }}</h1>
          <h3 v-if="bannerInfo.description" class="left-subtitle" :style="`color: ${bannerInfo.color}`">{{ bannerInfo.description }}</h3>
        </div>
        <!-- 自定义模板 -->
        <div v-if="isCustom">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bannerInfo: {
      type: Object,
      default: function() {
        return {}
      }
    },
    // 自定义模板
    isCustom: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      bannerInfoDefault: { // banner高度
        height: 558
      }
    }
  },
  computed: {
    bannerInfoConfig() {
      return {
        ...this.bannerInfoDefault,
        ...this.bannerInfo
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common';
.public-banner {
  .banner-bg {
    width: 100%;
    position: relative;
    .banner-info {
      height: 100%;
      position: relative;
      // 带标题 居左
      .title-left-box {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        .left-title {
          font-size: 48px;
          font-weight: bold;
          line-height: 48px;
          color: #fff;
        }
        .left-subtitle {
          font-size: 18px;
          font-weight: 400;
          color: #666666;
          line-height: 26px;
          margin-top: 24px;
          color: #fff;
        }
        &.white-title {
          .left-title,
          .left-subtitle {
            color: #fff;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    margin-top: 93px;
    .banner-bg {
      height: 420px !important;
    }
  }
  @media screen and (max-width: 768px) {
    .banner-bg {
      height: 240px !important;
      .banner-info {
        .title-left-box {
          .left-title {
            font-size: 28px;
            line-height: 36px;
          }
          .left-subtitle {
            font-size: 14px;
            line-height: 22px;
            margin-top: 16px;
          }
        }
      }
    }
  }
}
</style>
