import { fetch } from '@/plugins/axios.js'
import lang from '@/utils/lang'
import site from '@/utils/site'

const apiUrl = {
  solutions: `/solution/list`, // 解决方案首页
  solutionsDetail: `/solution/detail` // 解决方案首页
}

// 解决方案首页
export const getSolutions = () => fetch(`${apiUrl.solutions}/${lang}/${site}`)
// 解决方案详情
export const getSolutionsDetail = (params) => fetch(`${apiUrl.solutionsDetail}/${lang}/${site}` + `?id=${params.id}`)

