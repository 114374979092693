<template>
  <div class="public-bread-crumb">
    <div class="container">
      <el-breadcrumb separator=">">
        <!-- <el-breadcrumb-item :to="{ path: `/${this.$route.params.lang}` }">{{ LP.lang_home }}</el-breadcrumb-item> -->
        <el-breadcrumb-item
          v-for="(item, index) in breadCrumb"
          :key="index"
          class="header-item"
          :to="item.nav_link_name === 'Home' ? `/${$route.params.site}/${$route.params.lang}/` : `/${$route.params.site}/${$route.params.lang}/${item.nav_link_name}` "
        >{{ item.nav_name }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 面包屑
    breadCrumb: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common';
.public-bread-crumb {
  padding: 16px 0;
  background: #fff;
  /deep/ .el-breadcrumb__item {
    font-size: 14px;
    line-height: 19px;
    .el-breadcrumb__separator {
      color: #333;
    }
    .el-breadcrumb__inner.is-link {
      font-weight: 400;
      .transition;
      &:hover {
        color: @theme;
      }
    }
    &:last-child {
      .el-breadcrumb__inner {
        cursor: pointer;
        .transition;
        &:hover {
          color: @theme;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .public-bread-crumb {
    display: none;
  }
}
</style>
