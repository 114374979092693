<template>
  <div class="solutions-detail-wrap">
    <public-banner :banner-info="bannerInfo" />
    <public-bread-crumb :bread-crumb="navigator" />
    <div class="detail-subnav" :class="{'subnav-fixed' : subnavTop >= 500, 'subnav-top': subnavUp === true}">
      <div class="subnav-box container">
        <div class="left-title">
          {{ title }}
        </div>
        <ul class="right-list">
          <li v-for="(item, index) in list" :key="index" class="list-item" @click="handleScroll(item.anchor)">{{ item.title }}</li>
        </ul>
      </div>
    </div>
    <!-- Overview -->
    <div id="solution_modular_one" class="overview container">
      <public-title :data-info="overview" />
      <div>
        {{ overviewInfo }}
      </div>
    </div>
    <!-- Highlights -->
    <div v-if="highlightsList.length > 0" id="solution_modular_two" class="container">
      <public-title id="overview" :data-info="highlights" />
      <div class="highlights">
        <ul class="highlights-list">
          <li v-for="(item, index) in highlightsList" :key="index" class="highlights-item">
            <div class="icon-box">
              <img :src="item.image" alt="" />
            </div>
            <div class="item-title">{{ item.name }}</div>
            <div class="item-des">{{ item.content }}</div>
          </li>
        </ul>
      </div>
    </div>
    <!-- Application Scenarios -->
    <div v-if="scenariosList.length > 0" id="solution_modular_three" class="container">
      <public-title :data-info="applicationScenarios" />
      <div class="scenarios">
        <ul class="scenarios-list">
          <li
            v-for="(item, index) in scenariosList"
            :key="index"
            class="scenarios-item"
          >
            <img :src="item.image" alt="" class="scenarios-img" />
            <div class="scenarios-btn">
              {{ item.name }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- Success Cases -->
    <div v-if="successList.length > 0" id="solution_modular_four" class="container">
      <success-cases :data="successList" />
    </div>
    <!-- Recommended Products -->
    <div v-if="productsList.length > 0" id="solution_modular_five" class="recommended">
      <public-title :data-info="recommendedProducts" />
      <ul class="recommended-list container">
        <li v-for="(item, index) in productsList" :key="index" class="recommended-item">
          <router-link :to="`/${$route.params.site}/${$route.params.lang}/products/productDetail?product_id=${item.product_id}`">
            <div class="img-box">
              <img :src="item.product_thumb" alt="" />
            </div>
            <div class="content-box">
              <div class="title-box">
                <span class="content-title ellipsis">{{ item.external_model }}</span>
                <i class="iconfont icon-a-youjiantou1x"></i>
              </div>
              <div class="content-des">
                {{ item.product_description }}
              </div>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PublicBanner from '@/components/public/PublicBanner.vue'
import PublicBreadCrumb from '@/components/public/PublicBreadCrumb.vue'
import PublicTitle from '@/components/public/PublicTitle.vue'
import SuccessCases from '@/components/products/productCategories/SuccessCases.vue'
import { scrollToTop, calculateTop } from '@/utils/utils.js'
import { getSolutionsDetail } from '@/api/solutions'

export default {
  components: {
    PublicBanner,
    PublicBreadCrumb,
    PublicTitle,
    SuccessCases
  },
  data() {
    return {
      bannerInfo: {},
      list: [],
      subnavTop: 0,
      subnavUp: false,
      i: 0,
      overview: {
        title: this.LP.lang_overview
      },
      overviewInfo: '',
      highlights: {
        title: this.LP.lang_highlights
      },
      applicationScenarios: {
        title: this.LP.lang_application_scenarios
      },
      recommendedProducts: {
        title: this.LP.lang_recommend_products
      },
      highlightsList: [],
      scenariosList: [],
      productsList: [],
      successList: [],
      detailId: null,
      title: ''
    }
  },
  computed: {
    ...mapState({
      banner: state => state.common.banner,
      navigator: state => state.common.navigator
    })
  },
  watch: {
    '$route'(to, from) {
      if (to.query.id !== from.query.id) {
        this.getData()
      }
    }
  },
  created() {
    this.$store.commit('header/SET_WHITE_HEADER', false)
    if (this.$route.fullPath.includes('consumer')) {
      this.$router.push({
        path: `/${this.$route.params.site}/${this.$route.params.lang}/404`
      })
    }
    this.detailId = this.$route.query.id
    this.getData()
  },
  mounted() {
    // 获取浏览器滚轮
    window.addEventListener('scroll', () => {
      this.subnavTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
      // 鼠标向上滚动，二级导航置顶，直到滚动到初始位置
      var scroll = document.documentElement.scrollTop - this.i
      this.i = document.documentElement.scrollTop
      scroll < 0 ? this.subnavUp = true : this.subnavUp = false
    })
  },
  methods: {
    // 获取banner以及详情信息
    async getData() {
      if (this.$route.fullPath.includes('business')) {
        await this.$store.dispatch('common/getBanner', { banner_id: 190 })
        this.bannerInfo = this.banner[0]
        await getSolutionsDetail({ id: this.$route.query.id }).then(res => {
          ({ overview: this.overviewInfo, highlights: this.highlightsList, application_scenario: this.scenariosList, success_cases: this.successList, recommended_products: this.productsList, title: this.title } = res.data.solution)
          this.list = res.data.top_nav_arr.map(item => ({
            ...item,
            title: item.name,
            anchor: item.solution_modular
          }))
          this.$store.commit('common/SET_MATE', res.data.seo)
        })
        await this.$store.dispatch('common/getNavigator', { type: 'solution_detail', id: this.$route.query.id, level: 1 })
      }
    },
    // 二级导航定位
    handleScroll(anchor) {
      const node = document.querySelector(`#${anchor}`)
      const offsetTop = calculateTop(node)
      scrollToTop(offsetTop - 90)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.solutions-detail-wrap {
  padding-bottom: 72px;
  /deep/ .public-bread-crumb {
    padding: 16px 0 24px;
    .header-item {
      margin-left: 11px;
    }
  }
  .detail-subnav {
    border-bottom: 1px solid #ddd;
    padding-bottom: 16px;
    .subnav-box {
      display: flex;
      justify-content: space-between;
      .left-title {
        font-size: 18px;
        font-weight: bold;
        color: #232931;
        line-height: 24px;
      }
      .right-list {
        display: flex;
        .list-item {
          cursor: pointer;
          margin-left: 24px;
          font-size: 14px;
          color: #333333;
          line-height: 19px;
          .transition;
          &:hover {
            color: @theme;
          }
        }
      }
    }
  }
  .subnav-fixed {
    position: fixed;
    width: 100%;
    top: 79px;
    background: #fff;
    padding: 24px 0 16px 0;
    border-top: 1px solid #ddd;
    z-index: 1000;
  }
  .subnav-top {
    top: 0;
  }
  .overview {
    font-size: 14px;
    color: #666666;
    line-height: 19px;
  }
  .highlights {
    .highlights-list {
      display: flex;
      .highlights-item {
        width: 32.5%;
        padding: 24px 16px 27px;
        margin-right: 16px;
        box-shadow: 0px 6px 18px 0px rgba(38,38,38,0.14);
        border-radius: 12px;
        .icon-box {
          text-align: center;
          img {
            width: 48px;
            height: 48px;
          }
        }
        .item-title {
          text-align: center;
          margin: 10px 0 16px;
          font-size: 18px;
          font-weight: 600;
          color: #232931;
          line-height: 18px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .scenarios {
    .scenarios-list {
      display: flex;
      .scenarios-item {
        width: 32.5%;
        margin-right: 16px;
        position: relative;
        overflow: hidden;
        border-radius: 12px;
        .scenarios-img {
          width: 100%;
          height: 100%;
          .transition;
        }
        .scenarios-btn {
          position: absolute;
          bottom: 32px;
          left: 50%;
          transform: translateX(-50%);
          padding: 11px 20px;
          font-size: 18px;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 18px;
          background: #0E5FAC;
          border-radius: 33px;
          .transition;
        }
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          .scenarios-img {
            .scale;
          }
          .scenarios-btn {
            opacity: 0.8;
          }
        }
      }
    }
  }
  .recommended {
    margin-top: 48px;
    padding-bottom: 72px;
    background-color: #F4F4F5;
    /deep/ .public-title {
      .text-box {
        padding-top: 24px;
      }
    }
    .recommended-list {
      display: flex;
      .recommended-item {
        width: 24%;
        margin-right: 16px;
        background-color: #fff;
        .img-box {
          padding: 0 27px;
          img {
            width: 100%;
            height: 100%;
            .transition;
          }
        }
        .content-box {
          padding: 8px 16px;
          border-top: 1px solid #E4E7ED;
          .title-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #333333;
            .transition;
            .content-title {
              font-size: 18px;
              line-height: 24px;
            }
            .iconfont {
              font-size: 18px;
            }
          }
          .content-des {
            height: 40px;
            overflow: hidden;
            margin-top: 8px;
            font-size: 14px;
            color: #666666;
            line-height: 19px;
          }
        }
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          .img-box {
            img {
              .scale;
            }
          }
          .content-box {
            .title-box {
              color: @theme;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .highlights {
      .highlights-list {
        .highlights-item {
          padding: 16px 8px 16px;
          .icon-box {
            img {
              width: 36px;
              height: 36px;
            }
          }
          .item-title {
            font-size: 16px;;
          }
        }
      }
    }
    .scenarios {
      .scenarios-list {
        .scenarios-item {
          .scenarios-btn {
            padding: 8px 16px;
            bottom: 16px;
            font-size: 16px;
          }
        }
      }
    }
    .recommended {
      .recommended-list {
        .recommended-item {
          .img-box {
            padding: 0 16px;
          }
          .content-box {
            .title-box {
              .content-title {
                font-size: 16px;
                line-height: 22px;
              }
              .iconfont {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .public-bread-crumb {
      display: none;
    }
    .detail-subnav {
      padding-bottom: 0;
      .subnav-box {
        display: block;
        .left-title {
          text-align: center;
          margin: 18px 0 10px;
        }
        .right-list {
          overflow-x: auto;
          padding-bottom: 16px;
          .list-item {
            white-space: nowrap;
          }
        }
      }
    }
    .subnav-fixed {
      top: 93px;
      border-top: none;
      padding: 0;
    }
    .highlights {
      .highlights-list {
        flex-wrap: wrap;
        .highlights-item {
          width: 100%;
          margin-right: 0;
          & + .highlights-item {
            margin-top: 16px;
          }
        }
      }
    }
    .scenarios {
      .scenarios-list {
        flex-wrap: wrap;
        .scenarios-item {
          width: 100%;
          margin-right: 0;
          & + .scenarios-item {
            margin-top: 16px;
          }
        }
      }
    }
    .recommended {
      margin-top: 48px;
      padding-bottom: 40px;
      .recommended-list {
        flex-wrap: wrap;
        .recommended-item {
          width: 100%;
          margin-right: 0;
          & + .recommended-item {
            margin-top: 16px;
          }
        }
      }
    }
  }
}
</style>
